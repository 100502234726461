<template>
  <div>
    <base-page-heading
      title="Changelog - Whats new?"
      subtitle="Here you'll find recent changes to the Scopes Software, including updates, bug fixes and new features."
    />
    <div class="content pt-3">
      <b-row
        v-for="(entry, index) in changelog"
        :key="index"
        class="mb-3"
      >
        <b-col>
          <b-row>
            <b-col>
              <h4>{{ entry.date | dateFormat }}</h4>
            </b-col>
          </b-row>
          <b-row
            v-for="(change, changeIndex) in entry.changes"
            :key="`changes-${changeIndex}`"
          >
            <b-col>{{ change }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import changelog from '@/data/changelog.json';

export default {
  name: 'Dashboard',
  data() {
    return {
      changelog: changelog,
    };
  },
};
</script>
