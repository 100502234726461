<template>
  <v-select
    :id="id"
    :options="filteredOptions"
    :class="selectClass"
    :placeholder="placeholder"
    :label="selectLabel"
    :value="record"
    @search="onSearch"
    :filterable="false"
    @input="onInput"
  >
    <template #option="option">
      {{ option.name }}
    </template>
    <template #no-options="{ search, searching }">
      <template v-if="searching">
        No results found for
        <em>{{ search }}</em>
      </template>
      <em
        style="opacity: 0.7"
        v-else
      >Type to search for descriptions</em>
    </template>
    <template #selected-option="option">
      {{ option.name }}
    </template>
  </v-select>
</template>
<script>
import { debounce as _debounce, filter as _filter, isObject as _isObject } from 'lodash';

export default {
  name: 'QuoteItemCapexDescriptionSelect',
  props: {
    value: [Object, Number, String],
    id: {
      type: String,
      default: 'dollar-general-fexa-capex-description-select',
    },
    selectLabel: {
      type: String,
      default: 'name',
    },
    selectClass: {
      type: Object,
      default: () => new Object(),
    },
    placeholder: String,
  },
  data() {
    return {
      options: [],
      record: this.value,
      filteredOptions: [],
    };
  },
  mounted() {
    this.getRecordOptions();
  },
  methods: {
    getRecordOptions() {
      this.$store.cache.dispatch('getDollarGeneralFexaQuoteCapexDescriptionOptions').then((records) => {
        this.options = records;
        this.filteredOptions = records;
        if(!_isObject(this.record)) {
          this.record = records.find((option) => option.id == this.record);
        }
      });
    },
    onSearch(text, loading) {
      if (text.length) {
        loading(true);
        this.filterRecords(text, loading, this);
      }
    },
    onInput(option) {
      this.record = option;
      this.$emit('input', option);
    },
    filterRecords: _debounce((text, loading, vm) => {
      const regex = new RegExp(`${text}`, 'ig');
      vm.filteredOptions = _filter(vm.options, (option) => ((option.name || '').match(regex)));
      loading(false);
    }, 500),
  },
};
</script>